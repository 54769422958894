import * as React from 'react';
import { graphql } from 'gatsby';
import Seo from '@/components/Seo';
import Layout from '@/layouts/default';
import BlockContent from '@/components/BlockContent';
import BlockContentContainer from '@/components/BlockContentContainer';

export interface PageTemplateProps {
  pageContext: {
    language: string;
    languageLinks: LanguageLinks;
  };
  data: {
    page: Pick<GatsbyTypes.SanityPage, 'title' | '_rawBody'> & {
      readonly meta: Pick<GatsbyTypes.SanityMeta, 'title' | 'description'>;
      readonly mainMedia: GatsbyTypes.Maybe<{
        asset: GatsbyImageData;
      }>;
    };
  };
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  pageContext: { languageLinks },
  data: { page },
}) => {
  return (
    <Layout languageLinks={languageLinks}>
      <Seo
        title={page.meta!.title!}
        description={page.meta!.description}
        image={page!.mainMedia}
      />
      <BlockContentContainer>
        <BlockContent blocks={page?._rawBody ?? []} />
      </BlockContentContainer>
    </Layout>
  );
};

export default PageTemplate;

export const query = graphql`
  query Page($language: String!, $id: String!) {
    page: sanityPage(id: { eq: $id }) {
      title
      meta {
        title
        description
      }
      mainMedia {
        ...SanityCoverFields
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...LocaleStrings
    }
  }
`;
